
export const SESSION_STORAGE_KEYS = {
    DEMO_ENV_HOST: 'demo_env_host',
    DEMO_ENV_ROOT_PATH: 'demo_env_root_path',
    DEMO_DEFAULT_ENV: 'demo_default_env',
    DEMO_ENV: 'demo_env',
    DEMO_ENV_API: 'demo_env_api',
    DEMO_ENV_URI: 'demo_env_uri',
    DEMO_ENV_REDIRECT_URI: 'demo_env_redirect_uri',
    DEMO_ENV_CLIENT_ID: 'demo_env_client_id',
    DEMO_ENV_CLIENT_SECRET: 'demo_env_client_secret',
    DEMO_ENV_LOGIN_URI: 'demo_env_login_uri',   
    DEMO_ENV_REGISTER_URI: 'demo_env_register_uri',
    DEMO_ENV_GRANT_TYPE: 'demo_env_grant_type',
    DEMO_ENV_USER_INFO: 'demo_env_user_info',
    DEMO_ENV_OIDC_TOKEN_URI: 'demo_env_oidc_token_uri',
    DEMO_ENV_LOGOUT_URI: 'demo_env_logout_uri',
    DEMO_ENV_ACCESS_TOKEN: 'demo_env_access_token',
    DEMO_ENV_ID_TOKEN: 'demo_env_id_token',
    DEMO_ENV_CODE_VERIFIER: 'demo_env_code_verifier',
    DEMO_ENV_APP_CLIENT_ID: 'demo_env_app_client_id',
}


export const LOCAL_STORAGE_KEYS = {
    DEMO_ENV_APP_IDS: 'demo_env_app_ids',
    DEMO_DEFAULT_ENV:  'demo_default_env',
    VERSION: 'version',
}

export const setItemPersistent = (key, value) => {
    localStorage.setItem(key, value);
}

export const getItemPersistent = (key) => {
    return localStorage.getItem(key);
}

export const removeItemPersistent = (key) => {
    return localStorage.removeItem(key);
}

export const setItem = (key, value) => {
    sessionStorage.setItem(key, value);
}

export const getItem = (key) => {
    return sessionStorage.getItem(key);
}

export const removeItem = (key) => {
    return sessionStorage.removeItem(key);
}

export const clearCache = () => {
    sessionStorage.clear();
}
