import './MobileLogout.css';
import { useEffect, useState } from 'react';

function MobileLogout() {
  const [message, setMessage] = useState('Launching Aikyam Web App');
  const [retry, setRetry] = useState(true);



  const onClick=()=>{
    window.location.href = 'aikyamwebview://home';
  }

  const onOpenClick=()=>{
    window.location.href = 'aikyamwebview://home';
  }

  useEffect(() => {
    document.title = "Launching Aikyam Web App";
    onClick();

  }, []);
  return (
    <div style={{ textAlign: 'center', margin: "50px" }}>
      <h4>You have been successfully logged out!</h4>
      <h6>Please wait...</h6>
      <h1>{message}</h1>

      <br />
      {retry && <button className="dmp-btn dmp-btn--md dmp-btn--1" lpos="main content : optum home delivery pharmacy" onClick={onOpenClick}>Open Mobile app</button>}
    </div>
  );
}

export default MobileLogout;
