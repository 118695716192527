
import React, { useEffect } from 'react';
import {  getLoginUri, getRegisterUri, setEnv } from '../../../util/api';
function Card() {
  useEffect(() => {

  }, []);

  const onSignin = (env = 'HSID_STAGE') => {
    setEnv(env)
    setTimeout(() => { window.location.href = `${getLoginUri(env)}`;}, 500);
   
  }

  const onRegister = (env = 'HSID_STAGE') => {
    setEnv(env)
    setTimeout(() => { window.location.href = `${getRegisterUri(env)}`;}, 500);
  }

  const apps = [
    {
      name: "Optum Store",
      desc: "View and track orders and  shop faster by storing shipping and billing information.",
      env: "OPTUM_STORE"
    },
    {
      name: "Optum Bank",
      desc: "For members with a United Healthcare card or whose payment card says optumbank.com.",
      env: "OPTUM_BANK"
    },
    {
      name: "Optum Rx Health Care Professionals",
      desc: "Prescribers can access prior authorization systems and compliance requirements.",
      env: "OPTUM_RX"
    },
    {
      name: "Optum Pay",
      desc: "Access claims payments and electronic remittance advice to reconcile payments.",
      env: "HSID"
    },
    {
      name: "UnitedHealthcare Provider Portal",
      desc: "Submit claims, verify eligibility, check prior authorization requirements and more.",
      env: "MY_UHC"
    },
    {
      name: "Optum Financial",
      desc: "For members whose payment card says optumfinancial.com or connectyourcare.com.",
      env: "OPTUM_FINANCIAL"
    },
  ]

  const onecard = () => {
    return apps.map((app) => {
      return <div key={app.name} className="dmp-col-12 dmp-col-md-6 dmp-col-xl-4">
        <div id="o5-simple-card-grouping-1265631849" className="o5-simple-card-grouping__card o5-simple-card-grouping__brand-white o5-simple-card-grouping__simplecard_left" qa_handles="o5-simple-cards-grouping">
          <h2 className="o5-simple-card-grouping__HeadingH2 dmp-type__heading-3" >{app.name}</h2>
          <p>{app.env}</p>
          <div className="o5-simple-card-grouping__bodycopy dmp-type__body-2"><p><span className="o5-text__body-2">{app.desc}</span></p>
          </div>
          <div className="o5-simple-card-grouping__buttons">
            <button aria-label={`Sign in ${app.name} `} onClick={()=>onSignin(app.env)} className="dmp-btn dmp-btn--md dmp-btn--1" lpos="main content : optum home delivery pharmacy">Sign in</button>
            <button aria-label={`Register ${app.name} `} onClick={()=>onRegister(app.env)} className="dmp-btn dmp-btn--md dmp-btn--2" lpos="main content : optum home delivery pharmacy">Register</button>
          </div>
        </div>
      </div>
    });
  }

  return <React.Fragment>
    <div id="o5-simple-card-row--632831524" className="dmp-container">
      <section className="o5-simple-card-grouping__grid o5-simple-card-grouping__2 dmp-row">
        {onecard()}
      </section>
    </div>
  </React.Fragment>
}

export default Card;