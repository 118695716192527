// import {Buffer} from 'buffer';
// import {randomBytes, createHash} from "crypto-browserify";
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS, getItem, getItemPersistent, setItem, setItemPersistent } from "../cache";
import CryptoJS from 'crypto-js';

export const ENVS = {
    HSID_DEV: "HSID_DEV",
    HSID_STAGE: "HSID_STAGE",
    HSID_NON_PROD: "HSID_NON_PROD",
    HSID_PROD: "HSID_PROD",

    // CAPTAIN_STAGE: "CAPTAIN_STAGE",
    // GOVID_DEV:  "GOVID_DEV",
}

export const ENVS_ALIAS = {
    HSID_STAGE: "STAGE",
    HSID_NON_PROD: "NON PROD",
    HSID_PROD: "PROD",
    HSID_DEV: "DEV",
    CAPTAIN_STAGE: "STAGE",
    GOVID_DEV:  "DEV",
}

export const setDefaultEnv = (env) => {
    if(!env){
        env = getItemPersistent(LOCAL_STORAGE_KEYS.DEMO_DEFAULT_ENV) || 'HSID_STAGE';
    }
    setItemPersistent(LOCAL_STORAGE_KEYS.DEMO_DEFAULT_ENV, env);
}

export const getDefaultEnv = () => {
    const DEFAULT_ENV = getItemPersistent(LOCAL_STORAGE_KEYS.DEMO_DEFAULT_ENV) || 'HSID_STAGE';
    setDefaultEnv(DEFAULT_ENV);
    return DEFAULT_ENV;
}

export const getAdminPortal = () =>{
    const PORTALS = {
        undefined: "https://admin.stage.identity.healthsafe-id.com/app/index.html",
        HSID_STAGE: "https://admin.stage.identity.healthsafe-id.com/app/index.html",
        HSID_DEV: "https://admin.dev.identity.healthsafe-id.com/app/index.html",
        CAPTAIN_STAGE: "https://admin-stage.aikyam.com/app/index.html",
    }
    return PORTALS[getDefaultEnv()];
}

export const getEndUserPortal = (env) =>{
    const PORTALS = {
        undefined: "https://stage.identity.healthsafe-id.com",
        DEFAULT: "https://stage.identity.healthsafe-id.com",
        HSID_STAGE: "https://stage.identity.healthsafe-id.com",
        HSID_NON_PROD: "https://nonprod.identity.healthsafe-id.com",
        HSID_PROD: "https://identity.healthsafe-id.com",
        HSID_DEV: "https://dev.identity.healthsafe-id.com",
        CAPTAIN_STAGE: "https://captain-stage.aikyam.com",
        GOVID_DEV: "https://identity.dev.optumgovid.com",
    }
    return PORTALS[env];
}

const getHost = (env) => {
    const HOST = getEndUserPortal(env) || getItem(SESSION_STORAGE_KEYS.DEMO_ENV_HOST) || `${window.location.origin}`;
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_HOST, HOST);
    return HOST;
}

const getRootPath = () => {
    const ROOT_UI_PATH = getItem(SESSION_STORAGE_KEYS.DEMO_ENV_ROOT_PATH) || "/";
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_ROOT_PATH, ROOT_UI_PATH);
    return ROOT_UI_PATH;
}

const getEnv = () => {
    // const ENV = getItem(SESSION_STORAGE_KEYS.DEMO_ENV) || "DEFAULT";
    const ENV = getItemPersistent(SESSION_STORAGE_KEYS.DEMO_ENV) || "DEFAULT";
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV, ENV);
    return ENV;
}
export const ENV = getEnv();

const MAPPINGS = {
    KC_APPS: {
        API: "https://kc.apps.aikyam.com",
        URI: "https://kc.apps.aikyam.com",
        REDIRECT_URI: `${getHost()}${getRootPath()}callback`,
        CLIENT_ID: "publicgenie",
        CLIENT_SECRET: "f4b1c7d4-8e4d-4d5a-8f5e-4b6d3b9b4b4d",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: "https://kc.apps.aikyam.com/realms/healthsafeid/protocol/openid-connect/token",
        USER_INFO: "https://kc.apps.aikyam.com/oidc/userinfo?access_token",
        AUTHORIZE_URL: "https://kc.apps.aikyam.com/realms/healthsafeid/protocol/openid-connect/auth?client_id=publicgenie&response_type=code&scope=openid%20profile%20email&redirect_uri=http://localhost:3001/api/callback"
    },
    CAPTAIN: {
        API: "https://captain-stage.aikyam.com",
        URI: "https://captain-stage.aikyam.com",
        REDIRECT_URI: `${getHost()}${getRootPath()}callback`,
        CLIENT_ID: "35f20eedc4286b32048a51d9918ff83ab00cd41c58837501",
        CLIENT_SECRET: "18d2df8cd4661aadd10ec4322ea0a744255b93680b065dca3f5ecf58d0f30c046d2c158352e43ee533f0bd1593d0d248",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: "https://captain-stage.aikyam.com/oidc/token",
        USER_INFO: "https://captain-stage.aikyam.com/oidc/userinfo?access_token"
    },
    OPTUMGOVID: {
        API: "https://identity.dev.optumgovid.com",
        URI: "https://identity.dev.optumgovid.com",
        REDIRECT_URI: `${getHost()}${getRootPath()}callback`,
        CLIENT_ID: "9dc4d50b50764fae111a426d6af871bab5a7c796a4fef22b",
        CLIENT_SECRET: "2d6bfe326f16c42c8c52223c486e620460cef80c430493be365e8787ce3632bdb5918f2cfc5f8e71322c95b72df936bc",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: "https://identity.dev.optumgovid.com/oidc/token",
        USER_INFO: "https://identity.dev.optumgovid.com/oidc/userinfo?access_token"
    },
    HSID: {
        API: "https://dev.identity.healthsafe-id.com",
        URI: "https://dev.identity.healthsafe-id.com",
        REDIRECT_URI: `${getHost()}${getRootPath()}callback`,
        CLIENT_ID: "unified-unmatched-web",
        CLIENT_SECRET: "75f7bb63813e52fc13688a42450f2af3c9ed929896138c8add4544b55036afe87bf8c231a23ad5c2cec0d4fcbb3bea68",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: "https://dev.identity.healthsafe-id.com/oidc/token",
        USER_INFO: "https://dev.identity.healthsafe-id.com/oidc/userinfo?access_token"
    },
    OPTUM_STORE: {
        API: "https://dev.identity.healthsafe-id.com",
        URI: "https://dev.identity.healthsafe-id.com",
        REDIRECT_URI: `${getHost()}${getRootPath()}callback`,
        CLIENT_ID: "disneyoptumstoreclientid",
        CLIENT_SECRET: "41af6488547742cdc8061c1865e50aa261749f86b0dc3a29dc9734a6c4e188e9f5615c85fc485222cb602937a604ffbf",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: "https://dev.identity.healthsafe-id.com/oidc/token",
        USER_INFO: "https://dev.identity.healthsafe-id.com/oidc/userinfo?access_token"
    },
    OPTUM_FINANCIAL: {
        API: "https://dev.identity.healthsafe-id.com",
        URI: "https://dev.identity.healthsafe-id.com",
        REDIRECT_URI: `${getHost()}${getRootPath()}callback`,
        CLIENT_ID: "optum-financial-disney-web",
        CLIENT_SECRET: "3f87d2e058e7dbde2ab99fa234fdc01010f842095da058bb5e39c70ba598a32cddc275ba0c42697e3020d9bb711743f1",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: "https://dev.identity.healthsafe-id.com/oidc/token",
        USER_INFO: "https://dev.identity.healthsafe-id.com/oidc/userinfo?access_token"
    },
    OPTUM_BANK: {
        API: "https://dev.identity.healthsafe-id.com",
        URI: "https://dev.identity.healthsafe-id.com",
        REDIRECT_URI: `${getHost()}${getRootPath()}callback`,
        CLIENT_ID: "optum-bank-disney-web",
        CLIENT_SECRET: "eae7352141b3a9c2394fea081317d97812896324f5e4946c91069009dceb3f3d9942740902e02790cae812676176549f",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: "https://dev.identity.healthsafe-id.com/oidc/token",
        USER_INFO: "https://dev.identity.healthsafe-id.com/oidc/userinfo?access_token"
    },
    OPTUM_CARE: {
        API: "https://dev.identity.healthsafe-id.com",
        URI: "https://dev.identity.healthsafe-id.com",
        REDIRECT_URI: `${getHost()}${getRootPath()}callback`,
        CLIENT_ID: "optum-care-disney-web",
        CLIENT_SECRET: "3cd6a9cb7df58a67d3896339e802da863e60694823727e742fc349299316a61a0c3383136b41e66ae0a83d497fdd8ab4",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: "https://dev.identity.healthsafe-id.com/oidc/token",
        USER_INFO: "https://dev.identity.healthsafe-id.com/oidc/userinfo?access_token"
    },
    MY_UHC: {
        API: "https://dev.identity.healthsafe-id.com",
        URI: "https://dev.identity.healthsafe-id.com",
        REDIRECT_URI: `${getHost()}${getRootPath()}callback`,
        CLIENT_ID: "optum-uhc-disney-web",
        CLIENT_SECRET: "5039a6264765a0326c3bc1256eee74a48c620618e49604e05ef0783afe4280a81e28e77737de8052ba65bb3741f5cf78",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: "https://dev.identity.healthsafe-id.com/oidc/token",
        USER_INFO: "https://dev.identity.healthsafe-id.com/oidc/userinfo?access_token"
    },
    OPTUM_RX: {
        API: "https://dev.identity.healthsafe-id.com",
        URI: "https://dev.identity.healthsafe-id.com",
        REDIRECT_URI: `${getHost()}${getRootPath()}callback`,
        CLIENT_ID: "optum-rx-disney-web",
        CLIENT_SECRET: "d233cf8d12a91fb18933bee3680bacad66b9d79fd6704d451bc2fa18d861149cc3ceec942c4b51288388f3794cc462d2",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: "https://dev.identity.healthsafe-id.com/oidc/token",
        USER_INFO: "https://dev.identity.healthsafe-id.com/oidc/userinfo?access_token"
    },
    DEFAULT: {
        API: `${getHost(ENVS.HSID_STAGE)}`,
        URI: `${getHost(ENVS.HSID_STAGE)}`,
        REDIRECT_URI: `${window.location.origin}/callback`,
        CLIENT_ID: "",
        CLIENT_SECRET: "",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: `${getHost(ENVS.HSID_STAGE)}/oidc/token`,
        USER_INFO: `${getHost(ENVS.HSID_STAGE)}/oidc/userinfo?access_token`
    },
    HSID_DEV: {
        API: `${getHost(ENVS.HSID_DEV)}`,
        URI: `${getHost(ENVS.HSID_DEV)}`,
        REDIRECT_URI: `${window.location.origin}/callback`,
        CLIENT_ID: "",
        CLIENT_SECRET: "",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: `${getHost(ENVS.HSID_DEV)}/oidc/token`,
        USER_INFO: `${getHost(ENVS.HSID_DEV)}/oidc/userinfo?access_token`
    },
    HSID_STAGE: {
        API: `${getHost(ENVS.HSID_STAGE)}`,
        URI: `${getHost(ENVS.HSID_STAGE)}`,
        REDIRECT_URI: `${window.location.origin}/callback`,
        CLIENT_ID: "",
        CLIENT_SECRET: "",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: `${getHost(ENVS.HSID_STAGE)}/oidc/token`,
        USER_INFO: `${getHost(ENVS.HSID_STAGE)}/oidc/userinfo?access_token`
    },
    HSID_NON_PROD: {
        API: `${getHost(ENVS.HSID_NON_PROD)}`,
        URI: `${getHost(ENVS.HSID_NON_PROD)}`,
        REDIRECT_URI: `${window.location.origin}/callback`,
        CLIENT_ID: "",
        CLIENT_SECRET: "",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: `${getHost(ENVS.HSID_NON_PROD)}/oidc/token`,
        USER_INFO: `${getHost(ENVS.HSID_NON_PROD)}/oidc/userinfo?access_token`
    },
    HSID_PROD: {
        API: `${getHost(ENVS.HSID_PROD)}`,
        URI: `${getHost(ENVS.HSID_PROD)}`,
        REDIRECT_URI: `${window.location.origin}/callback`,
        CLIENT_ID: "",
        CLIENT_SECRET: "",
        GRANT_TYPE: "authorization_code",
        OIDC_TOKEN_URI: `${getHost(ENVS.HSID_PROD)}/oidc/token`,
        USER_INFO: `${getHost(ENVS.HSID_PROD)}/oidc/userinfo?access_token`
    },
}

export const getApi = (env = getEnv()) => {
    const API = MAPPINGS[env].API;
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_API, API);
    return API;
}

export const getUri = (env = getEnv()) => {
    const URI = MAPPINGS[env].URI;
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_URI, URI);
    return URI;
}

export const getRedirectUri = (env = getEnv()) => {
    const REDIRECT_URI = MAPPINGS[env].REDIRECT_URI;
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_REDIRECT_URI, REDIRECT_URI);
    return REDIRECT_URI;
}

export const getClientId = (env = getEnv()) => {
    const CLIENT_ID =  getItem(SESSION_STORAGE_KEYS.DEMO_ENV_CLIENT_ID) || MAPPINGS[env].CLIENT_ID
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_CLIENT_ID, CLIENT_ID);
    return CLIENT_ID;
}

export const getClientSecret = (env = getEnv()) => {
    const CLIENT_SECRET = MAPPINGS[env].CLIENT_SECRET;
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_CLIENT_SECRET, CLIENT_SECRET);
    return CLIENT_SECRET;
}

export const getGrantType = (env = getEnv()) => {
    const GRANT_TYPE = MAPPINGS[env].GRANT_TYPE;
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_GRANT_TYPE, GRANT_TYPE);
    return GRANT_TYPE;
}


// export const API = getApi()
// export const URI = getUri()
// export const REDIRECT_URI = getRedirectUri()
// export const CLIENT_ID = getClientId()
// export const CLIENT_SECRET = getClientSecret()
// export const GRANT_TYPE = getGrantType()

export const getOidcTokenUri = (env = getEnv()) => {
    const OIDC_TOKEN_URI = getItem(SESSION_STORAGE_KEYS.DEMO_ENV_OIDC_TOKEN_URI) || MAPPINGS[env].OIDC_TOKEN_URI;
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_OIDC_TOKEN_URI, OIDC_TOKEN_URI);
    return OIDC_TOKEN_URI;
}

export const getUserInfo = (env = getEnv()) => {
    const USER_INFO = getItem(SESSION_STORAGE_KEYS.DEMO_ENV_USER_INFO) || MAPPINGS[env].USER_INFO;
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_USER_INFO, USER_INFO);
    return USER_INFO;
}

export const getVerifier = () => {
    const VERIFIER = getItem(SESSION_STORAGE_KEYS.DEMO_ENV_CODE_VERIFIER) || "rw8zQMfCNRfNd2gun-tjoFb-dSyFXQ-r_oHUT8MN1pU";//  generateCodeVerifier() || 
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_CODE_VERIFIER, VERIFIER);
    return VERIFIER;
}

const generateRandomBase64String = async (length = 24) =>
    Buffer.from(crypto.getRandomValues(new Uint8Array(length))).toString(
        'base64url'
    );

const computeCodeChallengeFromVerifier = async (verifier) => {
    const hashedValue = await crypto.subtle.digest(
        'SHA-256',
        new TextEncoder().encode(verifier)
    );
    return Buffer.from(hashedValue).toString('base64url');
};

const isCodeVerifierValid = async (codeVerifier, codeChallenge) =>
    (await computeCodeChallengeFromVerifier(codeVerifier)) === codeChallenge;

// const generatePKCEPair1 = () => {
//     const NUM_OF_BYTES = 22; // Total of 44 characters (1 Bytes = 2 char) (standard states that: 43 chars <= verifier <= 128 chars)
//     const HASH_ALG = "sha256";
//     const randomVerifier = randomBytes(NUM_OF_BYTES).toString('hex')
//     const hash = createHash(HASH_ALG).update(randomVerifier).digest('base64');
//     const challenge = hash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Clean base64 to make it URL safe
//     setItem(SESSION_STORAGE_KEYS.DEMO_ENV_CODE_VERIFIER, randomVerifier);
//     return { verifier: randomVerifier, challenge };
// }

// const generatePKCEPair2 = async() => {
//     const codeVerifier = await generateRandomBase64String();
//     const codeChallenge = await computeCodeChallengeFromVerifier(codeVerifier);
//     setItem(SESSION_STORAGE_KEYS.DEMO_ENV_CODE_VERIFIER, codeVerifier);
//     return { verifier: codeVerifier, codeChallenge };
// }


const generateCodeVerifier = () => {
    const rand = new Uint8Array(32);
    crypto.getRandomValues(rand);
    const codeVerifier = base64URL(new CryptoJS.lib.WordArray.init(rand));
    setItem(SESSION_STORAGE_KEYS.DEMO_ENV_CODE_VERIFIER, codeVerifier);
}

const generateCodeChallenge = () => {
    const code_verifier = generateCodeVerifier(); //getItem(SESSION_STORAGE_KEYS.DEMO_ENV_CODE_VERIFIER) || getVerifier();;
    return base64URL(CryptoJS.SHA256(code_verifier))
  }

const base64URL = (string) => {
    return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

// function base64URLEncode(str) {
//     return str.toString('base64')
//         .replace(/\+/g, '-')
//         .replace(/\//g, '_')
//         .replace(/=/g, '');
// }
// var verifier = base64URLEncode(crypto.randomBytes(32));


export const getLoginUri = (env) => {
    console.log(MAPPINGS[env])
    const challenge =  "W1jWMqW7mceRiCN4jhlg5w5uqEeiWO1VC2ZzJ67lIw8";//generateCodeChallenge();//
    // console.log(challenge, getVerifier())
    const defaultloginuri = `${getUri(env)}/oidc/authorize?scope=openid+email+profile+phone&display=popup&response_type=code&redirect_uri=${getRedirectUri(env)}&state=Fd7Uz6Tl-IZD4-QeHJKIOfIIIiATL2CwQ9JGqrhNiO4&nonce=nlSgOOU2bohlwdMtdIj1iqSX7mZR6i7_DZcuQ5CY0xk&client_id=${getClientId(env)}&code_challenge_method=S256&code_challenge=${challenge}`;
    console.log(defaultloginuri, 'defaultloginuri')
    const LOGIN_URI = getItem(SESSION_STORAGE_KEYS.DEMO_ENV_LOGIN_URI) || defaultloginuri;
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_LOGIN_URI, LOGIN_URI);
    return LOGIN_URI;
}

export const getRegisterUri = (env) => {
    console.log(MAPPINGS[env])
    const challenge = "W1jWMqW7mceRiCN4jhlg5w5uqEeiWO1VC2ZzJ67lIw8";//generateCodeChallenge();//
    const defaultregisteruri = `${getUri(env)}/oidc/authorize?scope=openid+email+profile+phone&display=popup&response_type=code&redirect_uri=${getRedirectUri(env)}&state=Fd7Uz6Tl-IZD4-QeHJKIOfIIIiATL2CwQ9JGqrhNiO4&nonce=nlSgOOU2bohlwdMtdIj1iqSX7mZR6i7_DZcuQ5CY0xk&client_id=${getClientId(env)}&code_challenge_method=S256&code_challenge=${challenge}&prompt=create`;
    const REGISTER_URI = getItem(SESSION_STORAGE_KEYS.DEMO_ENV_REGISTER_URI) || defaultregisteruri;
    // setItem(SESSION_STORAGE_KEYS.DEMO_ENV_REGISTER_URI, REGISTER_URI);
    return REGISTER_URI;
}

export const getIdToken = () => {
    return getItem(SESSION_STORAGE_KEYS.DEMO_ENV_ID_TOKEN);
}

export const logoutUri = () => {
    const LOGOUT_URI = `${getUri()}/oidc/logout?id_token_hint=${getIdToken()}&post_logout_redirect_uri=${window.location.origin}`;
    return LOGOUT_URI
}


export const accountUri = () => {
    const ACCOUNT_URI = `${getUri()}/web/redirect?to=manage-profile`;
    return ACCOUNT_URI
}

// export const LOGIN_AUTHORIZE_URI = getLoginUri()
// export const REGISTER_AUTHORIZE_URI = getRegisterUri()
// export const OIDC_TOKEN_URI = getOidcTokenUri()
// export const USER_INFO = getUserInfo()

export const setUris = () => {
    getEnv();
    getApi();
    getUri();
    getRedirectUri();
    getClientId();
    getClientSecret();
    getGrantType();
    getOidcTokenUri();
    getUserInfo();
    getLoginUri();
    getRegisterUri();

}


export const setEnv = (env) => {
    // clear();
    setItem(SESSION_STORAGE_KEYS.DEMO_ENV, env);
    setItemPersistent(SESSION_STORAGE_KEYS.DEMO_ENV, env);
    // setUris();
}