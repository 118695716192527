
import './style.css';

import { getUserInfo, logoutUri, accountUri } from '../../../util/api';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import axios from 'axios';
import queryString from 'query-string';

function Home() {

  const [fullname, setFullname] = useState('Please wait...');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneVerified, setPhoneVerified] = useState('');
  const [emailVerified, setEmailVerified] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {

    try {
      const parsed = queryString.parse(location.search);
      const token = parsed.token;
      if (parsed.token) {
        axios.get(`${getUserInfo()}=${token}`).then(resp => {
          const { name, email, preferred_username, phone_number, email_verified, phone_number_verified } = resp.data;
          setFullname(name);
          setEmail(email);
          setUsername(preferred_username);
          setPhone(phone_number);
          setEmailVerified(email_verified);
          setPhoneVerified(phone_number_verified);
        }).catch(err => {
          console.log(err);
          setFullname('Guest User');
        });
      } else {
        setFullname('Guest User');
      }

    }

    catch (err) {
      console.log(err);
      setFullname('Guest User');
    }

  }, []);


  const logout = () => {
    window.location.href = logoutUri();
  }

  const onLogout = () => {
    // clearCache();
    // navigate("/", { replace: true });
    logout();
  }

  const gotoHome = () => {
    navigate("/");
  }

  const gottoAccount = () => {
    window.location.href = accountUri();
  }

  useEffect(() => {
    document.title = "Demo: Optum Welcome";
  }, []);

  return (
    <div className="root container responsivegrid">


      <div id="container-f2a6152336" className="cmp-container">



        <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

          <div className="o5-header-container aem-GridColumn aem-GridColumn--default--12">
            <header className="o5-header">
              <div className="o5-header__main warm-white">

                <div>

                  <div className="o5-dynamic-alerts" data-optum-cmp="o5-dynamic-alerts" qa_handles="O5-dynamic-alerts">

                  </div></div>

                <div className="o5-header__wrapper dmp-container">
                  <div className="o5-header__left col-sm-12 col-md-12 col-lg-6 col-xl-3">
                    <div className="o5-header__logo">
                      <div data-optum-cmp="o5-logo">



                        <div id="faviconLink" data-favicon="/content/dam/optum5/skins/icons/favicon.ico"> </div>


                        <div className="show-in-edit-mode faux-edit-bar">Site Logo</div>



                        <a className="o5-logo__link" href="https://www.optum.com" lpos="main content">
                          <img className="o5-logo__img o5-logo__img--175" src="https://www.optum.com/content/dam/optum4/images/logos/optum-logo-ora-rgb1.svg" alt="Optum" />

                        </a>





                      </div></div>
                  </div>
                  <div className="o5-header__hamburger">
                    <button className="o5-header__menu-btn o5-header__menu-btn--open closed o5-header__menu-btn-loaded" aria-label="menu" aria-expanded="false">
                      <span className="o5-header__menu-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </span>
                    </button>
                  </div>
                  <div className="o5-header__right col-sm-6 col-lg-6 col-xl-9">
                    <div className="o5-global-ues-search">







                      <div className="dmp-container o5-ues-search__container o5-ues-search__container--hide">
                        <span className="o5-ues-search__icon">
                          <svg className="ues-MuiBox-root o5-ues-search__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" focusable="false" aria-hidden="true">
                            <path className="ues-MuiBox-root ues-optumdotcom-search-0" d="M39.8 41.95 26.65 28.8q-1.5 1.3-3.5 2.025-2 .725-4.25.725-5.4 0-9.15-3.75T6 18.75q0-5.3 3.75-9.05 3.75-3.75 9.1-3.75 5.3 0 9.025 3.75 3.725 3.75 3.725 9.05 0 2.15-.7 4.15-.7 2-2.1 3.75L42 39.75Zm-20.95-13.4q4.05 0 6.9-2.875Q28.6 22.8 28.6 18.75t-2.85-6.925Q22.9 8.95 18.85 8.95q-4.1 0-6.975 2.875T9 18.75q0 4.05 2.875 6.925t6.975 2.875Z"></path></svg>
                        </span>
                        Search
                      </div>


                    </div>
                    <div className="o5-header__popout--overlay o5-header__right col-sm-6 col-lg-6 col-xl-9 overlay-popout-bg">
                      <div className="o5-header__popout" id="o5-header__popout">

                        <div className="o5-header__hamburger--mobile">
                          <button className="o5-header__menu-btn o5-header__menu-btn--close closed o5-header__menu-btn-loaded" aria-label="menu" aria-expanded="false">
                            <span className="o5-header__menu-icon">
                              <span></span>
                              <span></span>
                              <span></span>
                            </span>
                          </button>
                        </div>
                        <div className="o5-global-search">






                          <div className="o5-header-mobile-icon"></div>



                        </div>


                      </div>
                    </div>
                  </div>
                </div>



                <div>






                </div>



                <div className="o5-l2-nav">
                  <div data-optum-cmp="o5-l2-nav" qa_handles="o5-l2-nav">
                    <div className="o5-l2-nav__container-bg remove--style">
                      <div className="dmp-container ">
                        <div className="o5-l2-nav__container-content remove--style">
                          <nav className="o5-l2-nav o5-l2-web-nav">
                            <ul className="o5-l2-nav__wrapper" style={{ marginBottom: '0px' }}>



                            </ul>
                          </nav>
                          <div className="o5-l2-nav__mobile hidden">
                            <nav aria-label="Main" className="o5-l2-nav__mobile-nav">
                              <ul className="o5-l2-nav__mobile-wrapper">
                                <li className="o5-l2-nav__mobile-title dmp-type__body-3-bold">
                                  <button className="o5-l2-nav__btn dmp-type__body-3-bold mb-title-dd" aria-expanded="false">

                                    <span className="material-symbols-rounded" aria-hidden="true">
                                      expand_more
                                    </span>
                                  </button>
                                  <div className="o5-l2-mb-title-dd o5-l2-nav__sub--hidden">
                                    <ul style={{ paddingLeft: "0" }}>



                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </nav>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="o5-l2-nav-standalone" data-optum-cmp="o5-l2-nav-standalone" qa_handles="o5-l2-nav-standalone">
                    <div className="o5-l2-nav-standalone__container-bg remove--style">
                      <div className="dmp-container ">
                        <div className="o5-l2-nav-standalone__container-content remove--style">
                          <nav className="o5-l2-nav-standalone o5-l2-web-nav">
                            <ul className="o5-l2-nav-standalone__wrapper" style={{ marginBottom: '0px' }}>



                            </ul>
                          </nav>
                          <div className="o5-l2-nav-standalone__mobile hidden">
                            <nav aria-label="Main" className="o5-l2-nav-standalone__mobile-nav">
                              <ul className="o5-l2-nav-standalone__mobile-wrapper">
                                <li className="o5-l2-nav-standalone__mobile-title dmp-type__body-3-bold">
                                  <button className="o5-l2-nav-standalone__btn dmp-type__body-3-bold mb-title-dd" aria-expanded="false">
                                    <span className="material-symbols-rounded" aria-hidden="true">
                                      expand_more
                                    </span>
                                  </button>
                                  <div className="o5-l2-mb-title-dd o5-l2-nav-standalone__sub--hidden">
                                    <ul style={{ paddingLeft: "0" }}>



                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </nav>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div></div>
                <div className="breadcrumb__wrapper">


                  <div className="breadcrumb__bg" data-optum-cmp="breadcrumb" qa_handles="breadcrumb">
                    <div className="dmp-container">



                      <nav className="breadcrumb_comp dmp-type__small-1" aria-label="Breadcrumb">
                        <ul data-qahandle="breadcrumbs" className="unstyled breadcrumb-nav horizontal-list" data-attr="/content/optum5">
                          <li className="breadcrumb__item mobile-parent" qa_handles="o5-breadcrumb__item">
                            <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18" fill="#4b4d4f" className="breadcrumb__arrow-mobile" aria-hidden="true">
                              <path d="M530-270 350-450q-7-7-10-14.5t-3-15.5q0-8 3-15.5t10-14.5l180-180q13-13 30-13t30 13q13 13 13 30t-13 30L440-480l150 150q13 13 13 30t-13 30q-13 13-30 13t-30-13Z"></path>
                            </svg>
                            <a className="breadcrumb__home dmp-type__body-3-bold" style={{ pointerEvents: 'initial!important' }} onClick={gotoHome} lpos="main content">Home</a>
                          </li>

                          <li className="breadcrumb__item animated fadeIn" qa_handles="o5-breadcrumb__item"><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18" fill="#4b4d4f" className="breadcrumb__arrow" qa_handles="o5-breadcrumb__arrow" aria-hidden="true"><path d="M348-268q-11-11-11-28t11-28l156-156-156-156q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11Z"></path></svg><span className="breadcrumb__link disabled-item" qa_handles="o5-breadcrumb__linkdisableditem">Home</span><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18" fill="#4b4d4f" className="breadcrumb__arrow-mobile" aria-hidden="true"><path d="M530-270 350-450q-7-7-10-14.5t-3-15.5q0-8 3-15.5t10-14.5l180-180q13-13 30-13t30 13q13 13 13 30t-13 30L440-480l150 150q13 13 13 30t-13 30q-13 13-30 13t-30-13Z"></path></svg></li></ul>
                      </nav>








                    </div>
                  </div>
                </div></div></header></div>
          <main className="container responsivegrid aem-GridColumn aem-GridColumn--default--12">


            <div id="main-content" className="cmp-container" tabindex="-1">



              <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                <div className="container responsivegrid aem-GridColumn aem-GridColumn--default--12">


                  <div id="container-92df31e92b" className="cmp-container">



                    <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">


                      <div className="o5-generic-hero-banner aem-GridColumn aem-GridColumn--default--12">
                        <div id="o5-generic-hero-banner-1123075157" className="cmp-o5-generic-hero-banner" data-optum-cmp="o5-generic-hero-banner" qa_handles="O5-generic-hero-banner">



                          <div className="o5-generic-hero-banner__container-generic o5-generic-hero-banner__brand-white dmp-container-fluid analytics_75handle">
                            <div className="dmp-container">
                              <div className="dmp-row o5-generic-hero-banner__rowClass">
                                <div className="dmp-col o5-generic-hero-banner__textAlignContainer">
                                  <div className="o5-generic-hero-banner__genericSecWidth o5-generic-hero-banner__genericleftSecWidth">
                                    <div className="o5-generic-hero-banner__content-generic-leftAlign margin-bottom-30">
                                      <div className="o5-generic-hero-banner__header--genericleft">

                                      {fullname !== "Guest User" && <h1 id="heading-1123075157" className="o5-generic-hero-banner__main-heading_1 dmp-type__display-2">You have successfully logged into the portal</h1>}
                                        {fullname !== "Guest User" && <button onClick={gottoAccount} className="dmp-btn dmp-btn--md dmp-btn--1" lpos="main content : optum home delivery pharmacy">My Account</button>}
                                        {fullname !== "Guest User" && <button onClick={onLogout} className="dmp-btn dmp-btn--md dmp-btn--2" lpos="main content : optum home delivery pharmacy">Logout</button>}
                                        {/* <div id="o5-generic-hero-banner_text-1123075157" className="o5-generic-hero-banner__bodyCopy-generic dmp-type__body-1"><p>Welcome back</p></div> */}
                                      </div>








                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div></div>
                      <div className="o5-tabs dmp-margin__top-0 dmp-margin__bottom-0 aem-GridColumn aem-GridColumn--default--12">


                        <div id="o5-tabs--2137963809" className="o5-tabs__wrapper dmp-padding__top-80 dmp-padding__bottom-80" qa_handles="o5-tabs_wrapper">

                          <div className="o5-tabs__tabs jsTabParent" qa_handles="o5-tabs__container">


                            <div className="o5-tabs__oc-tabs-panels jsTabsPanelContainer" id="panelContainer">
                              <div tabindex="0" role="tabpanel" id="panel0" aria-labelledby="tab0" className="jsTabPanel o5-tabs__oc-tabs-panel o5-tabs__show-data" data-tab-panel="0">
                                <div className="tab-0-o5-simple-card o5-simple-card">

                                  <div id="o5-simple-card--loggedinuser" data-optum-cmp="o5-simple-card" qa_handles="o5-simple-card" data-aem-module="o5-simple-card" className="o5-simple-card-grouping__container     o5-simple-card-grouping__brand-secondary-3">


                                  <div className="o5-simple-card-grouping__rowcontainer">
   
                               <div className='set-margin-auto'>
                               {fullname && <h1 className="heading__title" style={{ fontSize: "35px" }}>
                                      <span id="fullname" style={{ color: "#ce4817" }}> <strong>{fullname}</strong></span>

                                    </h1>}
                                    {email && <p>
                                      <span id="email" > Email Id: <strong>{email}</strong></span> {emailVerified && <span style={{ color: `${emailVerified? 'green': 'red'}`}}> {emailVerified ? '(verified)': '(not verified)'} </span>} <br />
                                     
                                    </p>}
                                    {username && <p><span id="username" > Username: <strong> {username}</strong></span><br /></p>}
                                    {phone && <p><span id="phone" > Phone number: <strong> {phone}</strong></span> {phoneVerified && <span style={{ color: `${phoneVerified? 'green': 'red'}`}}> {phoneVerified ? '(verified)': '(not verified)'} </span>}</p> }
                                    <br />
                                    <br />
                                    
                                    <button onClick={gotoHome} className="dmp-btn dmp-btn--md dmp-btn--2" lpos="main content : optum home delivery pharmacy">Go to apps</button>

                                    
                               </div>
                                    </div>
                                    
    
                                  </div></div>

                              </div>


                            </div>
                          </div>
                        </div>
                      </div>


                    </div>

                  </div>


                </div>


              </div>

            </div>


          </main>
          <div className="o5-Back-to-Top aem-GridColumn aem-GridColumn--default--12"><div id="o5-Back-to-Top" className="o5-Back-to-Top" qa-handles="o5-Back-to-Top">
            <div className="o5back-top fixed hidden" id="back-top-fixed">
              <a href="#main-content" className="back-to-top back-top-fixed" qa-handles="back-top-fixed" lpos="main content : financial broker and consultant hub">
                <span className="material-symbols-rounded back-top__arrow" aria-hidden="true">keyboard_arrow_up</span>
                <span className="assistText-new">back to top</span>
              </a>

            </div>
            <div className="o5back-top static" style={{ visibility: "hidden" }} qa-handles="back-top-static">
              <span id="back-top-static" className="back-to-top static" style={{ visibility: "hidden" }} lpos="back to top">
                <span className="assistText-new">back to top</span>
                <span className="material-symbols-rounded back-top__arrow" aria-hidden="true">keyboard_arrow_up</span></span>
            </div>
          </div></div>



        </div>

      </div>


    </div>);
}

export default Home;
