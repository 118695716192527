
import { useLocation, useNavigate } from 'react-router';

import queryString from 'query-string';
import './MobileCallback.css';
import { useEffect, useState } from 'react';

function MobileCallback() {
  const [message, setMessage] = useState('Launching Aikyam Web App');
  const [retry, setRetry] = useState(false);
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const onClick=()=>{
    if(parsed.code){
      window.location.href = 'aikyamwebview://token?code=' + parsed.code;
    } else if(parsed.logout){
      window.location.href = 'aikyamwebview://home';
    } else{
      setRetry(true);
      onOpenClick();
    }
  }

  const onOpenClick=()=>{
    window.location.href = 'aikyamwebview://profile';
  }

  useEffect(() => {
    document.title = "Launching Aikyam Web App";
    console.log(parsed.code);
    onClick();

  }, []);
  return (
    <div style={{ textAlign: 'center', margin: "50px" }}>
      <h4>Please wait....</h4>
      <h1>{message}</h1>

      <br />
      {retry && <button className="dmp-btn dmp-btn--md dmp-btn--1" lpos="main content : optum home delivery pharmacy" onClick={onOpenClick}>Open Mobile app</button>}
    </div>
  );
}

export default MobileCallback;
