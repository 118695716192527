
import React, { useEffect } from 'react';
import {  getLoginUri, getLoginUriDefault, getRegisterUri, getRegisterUriDefault, setEnv } from '../../../util/api';
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS, getItem, getItemPersistent, setItem, setItemPersistent } from '../../../util/cache';
import { useNavigate } from 'react-router';
import { ENVS_ALIAS } from '../../../util/api';

// import plusimg from '../../../assets/images/plus.png';
const { forwardRef, useRef, useImperativeHandle } = React;
const Card = forwardRef((props, ref) =>  {

  const defaultapp = [{
    id: 98,
    name: "Optum Store",
    desc: "View and track orders and shop faster by storing shipping and billing information.",
    clientId:'IEIA994-PKCE',
    env: "HSID_NON_PROD"
  },{
    id: 99,
    name: "Optum Store",
    desc: "View and track orders and shop faster by storing shipping and billing information.",
    clientId:'optum-store-eda-demo-app-client',
    env: "DEFAULT"
  }]

  const navigate = useNavigate();
  const [apps, setApps] = React.useState(JSON.parse(getItemPersistent(LOCAL_STORAGE_KEYS.DEMO_ENV_APP_IDS)) || []);
  useEffect(() => {
    addnonprodapp()
  }, []);

  const addnonprodapp = () => {
    if(!getItemPersistent(LOCAL_STORAGE_KEYS.VERSION) || getItemPersistent(LOCAL_STORAGE_KEYS.VERSION) !== '1.0.0'){
      setItemPersistent(LOCAL_STORAGE_KEYS.VERSION, '1.0.0');
      const _apps = [...apps];
      _apps.unshift(...defaultapp);
      setItemPersistent(LOCAL_STORAGE_KEYS.DEMO_ENV_APP_IDS, JSON.stringify(_apps));
      setApps(_apps);
    }
  }

  const onSignin = (app) => {
    setEnv(app.env)
    setItem(SESSION_STORAGE_KEYS.DEMO_ENV_CLIENT_ID, app.clientId || app.client_id)
    setTimeout(() => { window.location.href = `${getLoginUri(app.env)}`;}, 500);
   
  }

  const deleteapp = (app) => {
    const newapps = apps.filter((a) => a.id !== app.id);
setApps(newapps)
setItemPersistent(LOCAL_STORAGE_KEYS.DEMO_ENV_APP_IDS, JSON.stringify(newapps))

if(newapps.length === 0) {
  navigate('/new', { replace: true });
}
   
  }

  const onRegister = (app) => {
    setEnv(app.env)
    setItem(SESSION_STORAGE_KEYS.DEMO_ENV_CLIENT_ID, app.clientId  || app.client_id)
    setTimeout(() => { window.location.href = `${getRegisterUri(app.env)}`;}, 500);
  }


  const addApp = () => {
    navigate("/new", { replace: true });
  }

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({

     clearAll () {
      setItemPersistent(LOCAL_STORAGE_KEYS.DEMO_ENV_APP_IDS, "[]");
     setApps([])
    }
  

  }));

  const clearAllApps = (e) => {
    // e.prevantDefault();
    e.stopPropagation();
    setItemPersistent(LOCAL_STORAGE_KEYS.DEMO_ENV_APP_IDS, "[]");
   setApps([]);
   navigate('/new', { replace: true });
  }



  const onecard = () => {
    return apps?.map((app, index) => {
      return <div key={app.name} className="dmp-col-12 dmp-col-md-6 dmp-col-xl-4">
        <div id="o5-simple-card-grouping-1265631849" className="o5-simple-card-grouping__card o5-simple-card-grouping__brand-white o5-simple-card-grouping__simplecard_left" qa_handles="o5-simple-cards-grouping">
          <h2 className="o5-simple-card-grouping__HeadingH2 dmp-type__heading-3" >{app.name}</h2>
          <p className={`tag-env ${app.env}`}>{app.env === 'DEFAULT' ? 'STAGE': ENVS_ALIAS[app.env]}</p>
          <div className="o5-simple-card-grouping__bodycopy dmp-type__body-2"><p><span className="o5-text__body-2">{app.desc}</span></p>
          </div>
          <div className="o5-simple-card-grouping__buttons">
            <button aria-label={`Sign in ${app.name} `} onClick={()=>onSignin(app)} className="dmp-btn dmp-btn--md dmp-btn--1" lpos="main content : optum home delivery pharmacy">Sign in</button>
            <button aria-label={`Register ${app.name} `} onClick={()=>onRegister(app)} className="dmp-btn dmp-btn--md dmp-btn--2" lpos="main content : optum home delivery pharmacy">Register</button>
          </div>
          <div className="deleteappcontainer">
          { app.id !== 98 && app.id !== 99  && <a style={{ pointerEvents: 'initial!important' }} onClick={()=>{deleteapp(app)}}>Delete</a> }
          </div>
        </div>
      </div>
    });
  }

  const nocard = () => {
    return <div className="dmp-col-12 dmp-col-md-6 dmp-col-xl-4" onClick={addApp} title='Add new applciation' style={{cursor: 'pointer'}}>
            <div id="o5-simple-card-grouping-1265631849" className="o5-simple-card-grouping__card o5-simple-card-grouping__brand-white o5-simple-card-grouping__simplecard_left" qa_handles="o5-simple-cards-grouping">

              {/* <img src={plusimg} /> */}
              {/* <h2 className="o5-simple-card-grouping__HeadingH2 dmp-type__heading-3" >{app.name}</h2>
              <div className="o5-simple-card-grouping__bodycopy dmp-type__body-2"><p><span className="o5-text__body-2">{app.desc}</span></p>
              </div>*/}
              <div className="o5-simple-card-grouping__buttons" style={{textAlign: 'center', flex: '1', alignSelf: 'center', transform: 'translate(10%, 30%)'}}>
               <div className='horizontal-bar-plus'></div>
              </div> 
              <div className="deleteappcontainer">
              { apps?.length > 0 && <a style={{ pointerEvents: 'initial!important', marginLeft: '20px' }} onClick={clearAllApps}>Clear All</a>}
              </div>
            </div>
          </div>
        }
  

  return <React.Fragment>
    <div id="o5-simple-card-row--632831524" className="dmp-container">
      <section className="o5-simple-card-grouping__grid o5-simple-card-grouping__2 dmp-row">
        {onecard()} {nocard()}
      </section>
      {/* { apps.length === 0 && (
        <div>
          <p>Sorry no applications found!!! Please click on the below button to add new application to the system.</p>
      
      </div>
    )}
      <br />
      <br />
      <br /> */}

      
     
    </div>
  </React.Fragment>
});

export default Card;