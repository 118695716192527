
import { useLocation, useNavigate } from 'react-router';

import queryString from 'query-string';
import axios from 'axios';
import './Callback.css';
import { useEffect, useState } from 'react';
import { getClientId, getClientSecret, getGrantType, getOidcTokenUri, getRedirectUri, getVerifier } from '../../util/api';
import { SESSION_STORAGE_KEYS, setItem } from '../../util/cache';

function Callback() {
  const [message, setMessage] = useState('Please wait...Your account is being authenticating!!!');
  const [retry, setRetry] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const parsed = queryString.parse(location.search);


  const getAccessToken = (verifier) => {
    const payload = {
      code: parsed.code,
      grant_type: getGrantType(),
      redirect_uri: getRedirectUri(),
      client_id: getClientId(),
    }
    if (verifier) {
      payload.code_verifier = verifier
    } else {
      payload.client_secret = getClientSecret()
    }

    const _payload = new URLSearchParams({
      ...payload
    });
    axios.post(getOidcTokenUri(), _payload,
      { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
    ).then(resp => {
      console.log(resp.data.access_token);
      setItem(SESSION_STORAGE_KEYS.DEMO_ENV_ACCESS_TOKEN, resp.data.access_token);
      setItem(SESSION_STORAGE_KEYS.DEMO_ENV_ID_TOKEN, resp.data.id_token);
      navigate(`/home?token=${resp.data.access_token}`, { replace: true });

    }).catch(err => {
      setMessage('Error occured while authenticating your account. Please try again later!!!');
      setRetry(true)
    });
  }


  useEffect(() => {
    document.title = "Demo: Optum Please wait";
    console.log(parsed.code);

    getAccessToken(getVerifier());

  }, []);
  return (
    <div style={{ textAlign: 'center', margin: "50px" }}>
      <h1>{message}</h1>

      <br />
      {retry && <button className="dmp-btn dmp-btn--md dmp-btn--1" lpos="main content : optum home delivery pharmacy" onClick={() => { navigate('/', { replace: true }) }}>Go to home</button>}
    </div>
  );
}

export default Callback;
